import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Collapse } from 'reactstrap';
import { formPostHandler } from "../dal/apiRequest";
import CustomSelect from '../components/Shared/customSelect';
import RecaptchaText from "../components/Shared/recaptchaText";

function SupportForm() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isOpen, setIsOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            full_name: '',
            email: '',
            feel: '',
            subject: '',
            platform: '',
            feedback: '',
            device_model: '',
            os_version: '',
            app_version: '',
            user_id: ''
        },
        validationSchema: Yup.object({
            full_name: Yup.string()
                .required('Required'),
            feel: Yup.string()
                .required('Required'),
            subject: Yup.string()
                .required('Required'),
            platform: Yup.string()
                .required('Required'),
            feedback: Yup.string()
                .required('Required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
        }),
        onSubmit: async (values, actions) => {
            const token = await executeRecaptcha("submit");
            values.token = token;
            const success = await formPostHandler("contact-forms", values, "Your request has been received. We will get back to you about the subject as soon as possible.")

            if (success) {
                actions.resetForm({
                    values: {
                        full_name: '',
                        email: '',
                        feel: '',
                        subject: '',
                        platform: '',
                        feedback: '',
                        device_model: '',
                        os_version: '',
                        app_version: '',
                        user_id: ''
                    },
                })
            } else {
                actions.resetForm({
                    values: {
                        full_name: values.full_name,
                        email: values.email,
                        feel: values.feel,
                        subject: values.subject,
                        platform: values.platform,
                        feedback: values.feedback,
                        device_model: values.device_model,
                        os_version: values.os_version,
                        app_version: values.app_version,
                        user_id: values.user_id
                    },
                })
            }
            actions.setSubmitting(false);
        },
    });

    const toggle = () => setIsOpen(!isOpen);

    return (
        <form className="form--primary" id="supportForm" onSubmit={formik.handleSubmit}>
            <div className="form-group">
                <label>Your Full Name</label>
                <input
                    className="form-control style--two"
                    id="name"
                    type="text"
                    name="full_name"
                    placeholder="Enter Your Full Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.full_name}
                />
                {formik.touched.full_name && formik.errors.full_name ? (
                    <label className="error">{formik.errors.full_name}</label>
                ) : null}
            </div>
            <div className="form-group">
                <label>Your Email</label>
                <input
                    className="form-control style--two"
                    id="email"
                    type="text"
                    name="email"
                    placeholder="Enter Your Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                    <label className="error">{formik.errors.email}</label>
                ) : null}
            </div>
            <div className="form-group form-group-radio">
                <label>How do you feel?</label>
                <div className="custom-radio">
                    <div className="custom-radio-item">
                        <input
                            className="form-control"
                            id="feel-smile"
                            type="radio"
                            name="feel"
                            value="smile"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <label htmlFor="feel-smile" title="Smile">
                            <i className="fas fa-smile"></i>
                        </label>
                        <div className="filter-active" aria-hidden="true"></div>
                    </div>
                    <div className="custom-radio-item">
                        <input
                            className="form-control"
                            id="feel-meh"
                            type="radio"
                            name="feel"
                            value="meh"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <label htmlFor="feel-meh" title="Meh">
                            <i className="fas fa-meh"></i>
                        </label>
                        <div className="filter-active" aria-hidden="true"></div>
                    </div>
                    <div className="custom-radio-item">
                        <input
                            className="form-control"
                            id="feel-frown"
                            type="radio"
                            name="feel"
                            value="frown"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <label htmlFor="feel-frown" title="Frown">
                            <i className="fas fa-frown"></i>
                        </label>
                        <div className="filter-active" aria-hidden="true"></div>
                    </div>
                </div>
                <div id="custom-radio-error"></div>
                {formik.touched.feel && formik.errors.feel ? (
                    <label className="error">{formik.errors.feel}</label>
                ) : null}
            </div>
            {/* <div className="form-group nice-select-group">
                <label>Subject</label>
                <select
                    className="nice-select"
                    name="subject"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subject}>
                    <option
                        value=""
                        data-display="--- Please choose an option ---"
                    >
                        --- Please choose an option ---
                      </option>
                    <option value="Technical support">
                        Technical Support
                      </option>
                    <option value="Billing question or issue">
                        Billing question or issue
                      </option>
                    <option value="Game installation trouble">
                        Game installation trouble
                      </option>
                    <option value="Question about how to play">
                        Question about how to play
                      </option>
                    <option value="General Feedback">General feedback</option>
                    <option value="Other">Other</option>
                </select>
                {formik.touched.subject && formik.errors.subject ? (
                    <label className="error">{formik.errors.subject}</label>
                ) : null}
            </div> */}


            <div className="form-group">
                <label>Subject</label>
                <CustomSelect
                    options={[
                        { value: "technical_support", label: "Technical Support" },
                        { value: "billing_question_or_issue", label: "Billing question or issue" },
                        { value: "question_about_how_to_play", label: "Question about how to play" },
                        { value: "general_feedback", label: "General feedback" },
                        { value: "other", label: "Other" },
                    ]}
                    fieldName={"subject"}
                    onChange={async (e) => {
                        await formik.setFieldValue("subject", e.value)
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.subject}
                />
                {formik.touched.subject && formik.errors.subject ? (
                    <label className="error">{formik.errors.subject}</label>
                ) : null}
            </div>

            {/* <div className="form-group nice-select-group">
                <label>Game</label>
                <select
                    className="nice-select"
                    name="game"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.game}>
                    <option
                        value=""
                        data-display="--- Please choose an option ---"
                    >
                        --- Please choose an option ---
                      </option>
                    <option value="the-war">The War</option>
                    <option value="the-sniper">The Sniper</option>
                </select>
                {formik.touched.game && formik.errors.game ? (
                    <label className="error">{formik.errors.game}</label>
                ) : null}
            </div> */}
            <div className="form-group">
                <label>Select Game</label>
                <CustomSelect
                    options={[
                        { value: "no_surrender", label: "No Surrender" }
                    ]}
                    fieldName={"game"}
                    onChange={async (e) => {
                        await formik.setFieldValue("game", e.value)
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.game}
                />
                {formik.touched.game && formik.errors.game ? (
                    <label className="error">{formik.errors.game}</label>
                ) : null}
            </div>
            {/* <div className="form-group nice-select-group">
                <label>Platform</label>
                <select
                    className="nice-select"
                    name="platform"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.platform}>
                    <option
                        value=""
                        data-display="--- Please choose an option ---"
                    >
                        --- Please choose an option ---
                      </option>
                    <option value="iOS">Apple iPad or iPhone</option>
                    <option value="Android">Android</option>
                    <option value="WP8">Windows Phone 8</option>
                    <option value="PC">PC/Mac</option>
                </select>
                {formik.touched.platform && formik.errors.platform ? (
                    <label className="error">{formik.errors.platform}</label>
                ) : null}
            </div> */}

            <div className="form-group">
                <label>Platform</label>
                <CustomSelect
                    options={[
                        { value: "ipad_iphone", label: "Apple iPad or iPhone" },
                        { value: "android", label: "Android" },
                        { value: "windows_phone_8", label: "Windows Phone 8" },
                        { value: "pc_mac", label: "PC/Mac" }
                    ]}
                    fieldName={"platform"}
                    onChange={async (e) => {
                        await formik.setFieldValue("platform", e.value)
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.platform}
                />
                {formik.touched.platform && formik.errors.platform ? (
                    <label className="error">{formik.errors.platform}</label>
                ) : null}
            </div>

            <div className="form-group">
                <label>Feedback</label>
                <textarea
                    className="form-control style--two"
                    id="feedback"
                    placeholder="Enter Your Feedback"
                    name="feedback"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.feedback}
                ></textarea>
                {formik.touched.feedback && formik.errors.feedback ? (
                    <label className="error">{formik.errors.feedback}</label>
                ) : null}
            </div>
            <button
                className="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#extraInformation"
                aria-expanded="false"
                aria-controls="extraInformation"
                onClick={() => toggle()}
            >
                <i className={`fas ${isOpen ? "fa-minus-square" : "fa-plus-square"} mr-2`}></i>Extra Information
            </button>
            {/* <div className="collapse" id="extraInformation"> */}
            <Collapse isOpen={isOpen}>
                <div className="mt-4">
                    <div className="form-group">
                        <label>Device Model</label>
                        <input
                            className="form-control style--two"
                            id="deviceModel"
                            type="text"
                            name="device_model"
                            placeholder="Enter Device Model"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.device_model}
                        />
                        {formik.touched.device_model && formik.errors.device_model ? (
                            <label className="error">{formik.errors.device_model}</label>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <label>OS Version</label>
                        <input
                            className="form-control style--two"
                            id="osVersion"
                            type="text"
                            name="os_version"
                            placeholder="Enter OS Version"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.os_version}
                        />
                        {formik.touched.os_version && formik.errors.os_version ? (
                            <label className="error">{formik.errors.os_version}</label>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <label>App Version</label>
                        <input
                            className="form-control style--two"
                            id="appVersion"
                            type="text"
                            name="app_version"
                            placeholder="Enter App Version"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.app_version}
                        />
                        {formik.touched.app_version && formik.errors.app_version ? (
                            <label className="error">{formik.errors.app_version}</label>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <label>User ID</label>
                        <input
                            className="form-control style--two"
                            id="userId"
                            type="text"
                            name="user_id"
                            placeholder="Enter User ID"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.user_id}
                        />
                        {formik.touched.user_id && formik.errors.user_id ? (
                            <label className="error">{formik.errors.user_id}</label>
                        ) : null}
                    </div>
                </div>
            </Collapse>
            {/* </div> */}
            <div className="mb-3">
                <button
                    disabled={formik.isSubmitting}
                    className="submit-btn"
                    type="submit">
                    Send
                </button>
            </div>
            <RecaptchaText />
        </form>
    )
}

export default SupportForm
